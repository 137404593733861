import { dialogCategory } from '../category/dialogCategory'
import { TMenu } from '../menu.interface'

export const accountsRole: TMenu[] = [
  dialogCategory,
  {
    title: 'Аккаунты',
    key: 'menu-accounts-gram',
    url: '/dialog/gram',
    icon: 'fe fe-database',
  },
  {
    title: 'Ответственные',
    key: 'menu-accounts-responsible',
    url: '/dialog/responsible',
    icon: 'fe fe-hard-drive',
  },
  {
    title: 'Прокси',
    key: 'menu-accounts-proxy',
    url: '/dialog/proxy',
    icon: 'fe fe-lock',
  },
]
