import { lazy } from 'react'
import { IRouter } from './Router.interface'

export const routes: IRouter[] = [
  // VB:REPLACE-START:ROUTER-CONFIG
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },

  // VB:REPLACE-END:ROUTER-CONFIG
  {
    path: '/team/list',
    Component: lazy(() => import('pages/1_team/workers')),
    exact: true,
  },
  {
    path: '/team/positions',
    Component: lazy(() => import('pages/1_team/positions')),
    exact: true,
  },
  {
    path: '/team/payroll',
    Component: lazy(() => import('pages/1_team/payroll')),
    exact: true,
  },
  {
    path: '/botPlatform/bots',
    Component: lazy(() => import('pages/2_botPlatform/bots')),
    exact: true,
  },
  {
    path: '/botPlatform/channels',
    Component: lazy(() => import('pages/2_botPlatform/channels')),
    exact: true,
  },
  {
    path: '/botPlatform/content',
    Component: lazy(() => import('pages/2_botPlatform/content')),
    exact: true,
  },
  {
    path: '/botPlatform/analytics',
    Component: lazy(() => import('pages/2_botPlatform/analytics')),
    exact: true,
  },
  // {
  //   path: '/botPlatform/mailings',
  //   Component: lazy(() => import('pages/2_botPlatform/mailings')),
  //   exact: true,
  // },
  {
    path: '/botPlatform/mail',
    Component: lazy(() => import('pages/2_botPlatform/mail')),
    exact: true,
  },
  {
    path: '/botPlatform/media',
    Component: lazy(() => import('pages/2_botPlatform/media')),
    exact: true,
  },
  {
    path: '/botPlatform/media/videos/:folder',
    Component: lazy(() => import('pages/2_botPlatform/media/videos')),
  },
  {
    path: '/botPlatform/media/images/:folder',
    Component: lazy(() => import('pages/2_botPlatform/media/images')),
  },
  {
    path: '/botPlatform/media/avatars/:folder',
    Component: lazy(() => import('pages/2_botPlatform/media/avatars')),
  },
  {
    path: '/botPlatform/media/stickers/:folder',
    Component: lazy(() => import('pages/2_botPlatform/media/stickers')),
  },
  {
    path: '/dialog/gram',
    Component: lazy(() => import('pages/3_dialogs/gram')),
    exact: true,
  },
  {
    path: '/dialog/proxy',
    Component: lazy(() => import('pages/3_dialogs/proxy')),
    exact: true,
  },
  {
    path: '/dialog/responsible',
    Component: lazy(() => import('pages/3_dialogs/responsible')),
    exact: true,
  },
  {
    path: '/dialog/analytics',
    Component: lazy(() => import('pages/3_dialogs/analytics')),
    exact: true,
  },
  {
    path: '/dialog/dialogflow',
    Component: lazy(() => import('pages/3_dialogs/dialogflow')),
    exact: true,
  },
  {
    path: '/dialog/messenger',
    Component: lazy(() => import('pages/3_dialogs/messenger')),
    exact: true,
  },
  {
    path: '/dialog/credentials',
    Component: lazy(() => import('pages/3_dialogs/credentials')),
    exact: true,
  },
  {
    path: '/dialog/crypto',
    Component: lazy(() => import('pages/3_dialogs/crypto')),
    exact: true,
  },
  {
    path: '/dialog/affiliate',
    Component: lazy(() => import('pages/3_dialogs/affiliate')),
    exact: true,
  },
  {
    path: '/dialog/screenSoft',
    Component: lazy(() => import('pages/3_dialogs/screenSoft')),
    exact: true,
  },
  {
    path: '/dialog/credentials/folder/:folderId',
    Component: lazy(() => import('pages/3_dialogs/credentials')),
  },
  {
    path: '/manager/tv',
    Component: lazy(() => import('pages/5_management/tv/tv')),
    exact: true,
  },
  {
    path: '/mail/analytics',
    Component: lazy(() => import('pages/mail/analytics')),
    exact: true,
  },
  {
    path: '/mail/analytics/:id',
    Component: lazy(() => import('pages/mail/analytics/infoMail')),
  },
  // Slaver bank
  {
    path: '/assignment/dashboard',
    Component: lazy(() => import('pages/6_assignment/mainScreen')),
    exact: true,
  },
  {
    path: '/project/settings',
    Component: lazy(() => import('pages/project/settings')),
    exact: true,
  },
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/password/:code',
    Component: lazy(() => import('pages/auth/new-password')),
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  {
    path: '/auth/project',
    Component: lazy(() => import('pages/auth/project')),
    exact: true,
  },
  {
    path: '/auth/invite/:id',
    Component: lazy(() => import('pages/auth/register')),
  },
  {
    path: '/project/settings',
    Component: lazy(() => import('pages/project/settings')),
  },
  {
    path: '/service/massDelete',
    Component: lazy(() => import('pages/service/massDelete')),
  },
  {
    path: '/service/crypto',
    Component: lazy(() => import('pages/service/crypto')),
  },
  {
    path: '/hallFame/:id',
    Component: lazy(() => import('pages/hallFame')),
  },
  {
    path: '/tv',
    Component: lazy(() => import('pages/tv')),
  },
]
