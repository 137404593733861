import { TMenu } from './menu.interface'

export const menuProjectOwner: TMenu[] = [
  {
    title: 'Статистика',
    key: 'menu-manager',
    url: '/manager',
    icon: 'fe fe-bar-chart',
    children: [
      {
        title: 'Боты',
        key: 'menu-analytics',
        url: '/botPlatform/analytics',
      },
      {
        title: 'Конверсии',
        key: 'menu-analytics-dialogs',
        url: '/dialog/analytics',
      },
      {
        title: 'Рассылки. Аналитика',
        key: 'menu-analytics-mail',
        url: '/mail/analytics',
      },
    ],
  },
  {
    category: true,
    title: 'Бот платформа',
    key: 'q7r2hr',
  },
  {
    title: 'Боты',
    key: 'menu-bots',
    url: '/botPlatform/bots',
    icon: 'fe fe-cpu',
  },
  {
    category: true,
    title: 'Контент',
    key: 'main-content',
  },
  {
    title: 'Каналы',
    key: 'menu-channels',
    url: '/botPlatform/channels',
    icon: 'fe fe-message-square',
  },
  {
    title: 'Скриншоты',
    key: 'menu-screen-soft',
    url: '/dialog/screenSoft',
    icon: 'fe fe-crop',
  },
  {
    title: 'Локализации',
    key: 'menu-content',
    url: '/botPlatform/content',
    icon: 'fe fe-file-text',
  },
  {
    title: 'Библиотека',
    key: 'menu-media',
    url: '/botPlatform/media',
    icon: 'fe fe-folder',
  },
  // {
  //   title: 'Рассылки',
  //   key: 'menu-mailings',
  //   url: '/botPlatform/mailings',
  //   icon: 'fe fe-mail',
  // },
  {
    title: 'Рассылки (new)',
    key: 'menu-mail',
    url: '/botPlatform/mail',
    icon: 'fe fe-mail text-amber-300',
  },
  {
    category: true,
    title: 'Диалоги',
    key: 'q7r1hr',
  },
  {
    title: 'Аккаунты',
    key: 'menu-accounts-gram',
    url: '/dialog/gram',
    icon: 'fe fe-database',
  },
  {
    title: 'Прокси',
    key: 'menu-accounts-proxy',
    url: '/dialog/proxy',
    icon: 'fe fe-lock',
  },
  {
    title: 'Терминал диалогов',
    key: 'menu-messenger',
    url: '/dialog/messenger',
    icon: 'fe fe-inbox',
  },
  {
    title: 'Dialogflow',
    key: 'menu-accounts-dialogflow',
    url: '/dialog/dialogflow',
    icon: 'fe fe-message-circle',
  },
  {
    category: true,
    title: 'Финансы',
    key: 'main-finance',
  },
  {
    title: 'Сотрудники',
    key: 'menu-team-list',
    url: '/team/list',
    icon: 'fe fe-user',
  },
  {
    title: 'Зарплата',
    key: 'menu-team-payroll',
    url: '/team/payroll',
    icon: 'fe fe-file-text',
  },
  {
    title: 'Ротация',
    key: 'menu-sales-creds',
    url: '/dialog/credentials',
    icon: 'fe fe-dollar-sign',
  },
  {
    title: 'Крипторотация',
    key: 'menu-sales-crypto',
    url: '/dialog/crypto',
    icon: 'fe fe-lock',
  },
  {
    title: 'Ротация партнерок',
    key: 'menu-sales-affiliate',
    url: '/dialog/affiliate',
    icon: 'fe fe-award',
  },
]
